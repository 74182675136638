import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

import * as PageStyles from './page.module.scss';

export default function NotFoundPage() {
  const pageMeta = {
    title: '404',
    description: 'Sorry 😔, the resource could not be located.',
    uri: '404',
  };

  return (
    <Layout>
      <Seo pageMeta={pageMeta} />
      <section aria-labelledby="404" className={PageStyles.notFoundSection}>
        <h1 id="404">404</h1>
        <p>
          Sorry{' '}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>
          , resource not found.
        </p>
      </section>
    </Layout>
  );
}
